export const initialText = `# Markdown2PDF 

## md2pdf useage:
1. Click button choose \`.md\` file.
2. Edit in editor (left panel)
3. Click **Transform**
4. Switch 'Destination' to **Save as PDF**
4. **Chrome recommended**

- You can use <span style="color:#0984e3">html</span> tag:
<blockquote>Hey I'm in blockquote!</blockquote>

## code:
\`\`\`javascript
// index.js
function Hello(){
  console.log('World!')
}
Hello();
\`\`\`

or this

\`\`\`python
# main.py
def awesome():
    print('awesome!')
awesome()
\`\`\`

`;
